<template>
  <div class="task-post-wrapper">
    <!-- Author + Timestamp -->
    <p class="task-post-meta">
      <strong v-if="authorName" class="is-size-7 has-margin-right-25">{{
        authorName + (authorIsAgent ? ` (Agent)` : ``)
      }}</strong>
      <small class="has-text-grey-light">
        {{
          $moment(dateCreated).calendar(null, {
            sameElse: "DD/MM/YYYY"
          })
        }}
      </small>
      <b-tooltip
        v-if="isFile && isPrivate"
        label="Private attachment"
        position="is-top"
        type="is-dark"
      >
        <b-icon
          icon="eye-slash"
          pack="far"
          size="is-small"
          class="has-margin-left-25"
        />
      </b-tooltip>

      <b-tooltip
        v-if="isFile && isNote"
        label="Internal attachment"
        position="is-top"
        type="is-warning"
      >
        <b-icon
          icon="clipboard"
          pack="far"
          size="is-small"
          class="has-margin-left-25"
        />
      </b-tooltip>
    </p>

    <b-icon
      v-if="isNote && isAgent && $task.isOpen && !isEditing"
      icon="thumbtack"
      class="has-cursor-pointer has-self-aligned-center"
      :class="{ 'has-text-grey-light': !isPinned }"
      size="is-small"
      @click.native="pinPost"
    />
    <span v-else />

    <!-- Edit Controls -->
    <b-dropdown
      v-if="isAuthor && $task.isOpen && !isEditing"
      :mobile-modal="false"
      position="is-bottom-left"
      class="task-post-dropdown"
    >
      <a slot="trigger" class="has-text-grey-light">
        <b-icon icon="ellipsis-h" size="is-small" />
      </a>
      <b-dropdown-item v-if="!isFile" @click="isEditing = true"
        >Edit</b-dropdown-item
      >
      <b-dropdown-item class="has-text-danger" @click="$emit('delete')"
        >Delete</b-dropdown-item
      >
    </b-dropdown>

    <!-- Body -->
    <div class="task-post-body">
      <div v-if="isEditing">
        <task-post-controls
          :message="message"
          :message-id="id"
          :task-id="task._id"
          :allow-files="false"
          :autofocus="true"
          :max-height="0"
          @onCancel="isEditing = false"
          @onEdit="onEdit"
        />
      </div>
      <!-- isFile -->
      <b-message
        v-else-if="isFile"
        :type="isNote ? 'is-warning' : isPrivate ? 'is-dark' : 'is-grey'"
      >
        <task-attachment
          v-if="isFile"
          :loading="isPending"
          :file-type="fileData.contentType"
          :file-name="getFileName(id, fileData)"
          :file-size="fileData.size"
          :file-ref="getFileRef(fileData)"
          :thumbnail-ref="getFileRef(fileThumbnail)"
          :show-relative-time="false"
        />
      </b-message>

      <!-- isNote -->
      <b-message v-else-if="isNote" type="is-warning">
        <urlify :text="message" tag="p"> <strong>Note:</strong>&nbsp; </urlify>
      </b-message>

      <!-- isPrivate -->
      <b-message v-else-if="isPrivate" type="is-dark">
        <urlify :text="message" tag="p">
          <strong>Private:</strong>&nbsp;
        </urlify>
      </b-message>

      <!-- isMessage -->
      <urlify v-else :text="message" tag="p" />
    </div>
  </div>
</template>

<script>
import { ref as storageRef } from "firebase/storage";
export default {
  name: "TaskPost",
  components: {
    "task-attachment": () => import("@shared/tasks/_taskAttachment"),
    "task-post-controls": () => import("@shared/tasks/posts/_postControls")
  },
  inject: ["$taskProvider"],
  props: {
    id: {
      type: String,
      required: true
    },
    task: {
      required: true,
      type: Object
    },
    message: {
      type: String,
      default: ""
    },
    dateCreated: {
      type: Date,
      required: true
    },
    authorId: {
      type: String,
      default: undefined
    },
    isLog: {
      type: Boolean,
      default: false
    },
    isPinned: {
      type: Boolean,
      default: false
    },
    isFile: {
      type: Boolean,
      default: false
    },
    isPending: {
      type: Boolean,
      default: false
    },
    isSiteChange: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    fileThumbnail: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    isNote: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    isAuthor() {
      return this.authorId === this.userId;
    },
    authorData() {
      return this.$_.get(this.task, `participantsData.${this.authorId}`, null);
    },
    authorName() {
      if (!this.authorId || this.isLog || this.authorId === "system")
        return "System";
      return this.$_.get(this.authorData, "name");
    },
    authorIsAgent() {
      return this.$_.get(this.task, `agents`, []).includes(this.authorId);
    },
    isSytem() {
      return this.authorId === "system" || !this.authorId;
    }
  },
  methods: {
    onEdit(snapshot) {
      this.isEditing = false;
      this.$emit("onEdit", snapshot);
      this.$bus.$emit("post-updated", snapshot);
    },
    pinPost() {
      return this.$store
        .dispatch("tasks/updatePost", {
          taskId: this.task._id,
          postId: this.id,
          payload: {
            isPinned: !this.isPinned
          }
        })
        .then(snapshot => {
          this.$emit("onEdit", snapshot);
          this.$toast.open({
            message: `Note ${snapshot.data().isPinned ? "pinned" : "unpinned"}`,
            position: "is-bottom",
            queue: false
          });
        });
    },
    getFileRef(fileInfo) {
      if (!fileInfo) return null;
      return storageRef(this.$storage(), fileInfo.path);
    },
    getFileName(id, fileInfo) {
      if (!fileInfo) return null;
      return fileInfo.path
        .split("/")
        .pop()
        .replace(`${id}_`, "");
    }
  }
};
</script>
